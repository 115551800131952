import fetcher from "lib/fetcher";
import useImmutableSWR from "swr/immutable";
import request from "../utils/request";

let LOCALAPI = `${
  typeof window === undefined
    ? process.env.SERVER
    : process.env.NEXT_PUBLIC_CLIENT_SERVER
}/${process.env.NEXT_PUBLIC_API_PREFIX}`;

// export function fetchTemplate(params, options = {}) {
//   const { id, slug, ...ohter } = params;
//   let url = `${LOCALAPI}/templates/`;
//   if (id) {
//     url += id;
//   }
//   if (slug) {
//     url += `detail/${slug}`;
//   }
//   let response = request({
//     url,
//     data: ohter,
//     method: "get",
//   });

//   return response;
// }

type FetchTemplateParams = {
  id?: number;
  slug?: string;
};

export function fetchTemplate(params: FetchTemplateParams, options = {}) {
  let url = `${LOCALAPI}/templates/`;
  if (params.id) {
    url += params.id;
  } else if (params.slug) {
    url += `detail/${params.slug}`;
  }

  return fetcher({
    url,
    method: "GET",
    ...options,
  })
    .then((res) => {
      return res.template;
    })
    .catch((err) => {
      return null;
    });
}

export function fetchCategoryTemplates(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/app/categories/${param.id}/templates`,
    method: "get",
  });
  return response;
}

export function getImagexPreview(param, options = {}) {
  let response = request({
    url: `${process.env.NEXT_PUBLIC_IMAGEX_SERVER}/imagex/preview`,
    data: param,
    method: "post",
    ...options,
  });
  return response;
}

export function postProducts(param = {}, options = {}) {
  let url;
  url = `${LOCALAPI}/products`;
  return request({
    url,
    method: "post",
    data: param,
    ...options,
  });
}

export function postFevorites(data: { template_id: number }, options = {}) {
  const { template_id } = data;

  let url = `${LOCALAPI}/templates/${template_id}/favorites`;

  return request({
    url,
    method: "post",
    ...options,
  });
}

type FetchTemplatesParams = {
  ids?: string;
  fields?: string;
  page?: number;
  collection?: string;
  category?: string;
  isFavorite?: boolean;
};

export function fetchTemplates(params: FetchTemplatesParams, options = {}) {
  let url = `${LOCALAPI}/templates`;

  return request({
    url,
    method: "get",
    data: params,
    ...options,
  })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return null;
    });
}

export function useTemplates(params: FetchTemplatesParams = {}) {
  return useImmutableSWR(["templates", params], (_, params) =>
    fetchTemplates(params)
  );
}

type FetchTemplateShippingProfilesParams = {
  id: number;
};

export function fetchTemplateShippingProfiles({
  id,
}: FetchTemplateShippingProfilesParams) {
  return fetcher({
    url: `${LOCALAPI}/templates/${id}/shippingProfiles`,
  }).then((res) => {
    return res["shipping_profiles"];
  });
}

type TemplateShippingProfileRatePrice = {
  zone_id: number;
  zone_name: string;
  is_default: number;
  first_price: string;
  additional_price: string;
};

type TemplateShippingProfileRate = {
  origin_country_id: number;
  name: string;
  min_shipping_time: number;
  max_shipping_time: number;
  min_processing_time: number;
  max_processing_time: number;
  prices: TemplateShippingProfileRatePrice[];
};

type TemplateShippingProfileGramPrice = {
  country_id: number;
  country: string;
  country_code: string;
  start_gram: number;
  price_per_gram: string;
  min_shipping_time: number;
  max_shipping_time: number;
};

type TemplateShippingProfile = {
  id: number;
  name: string;
  description: string;
  type: string;
  rates: TemplateShippingProfileRate[];
  gram_prices: TemplateShippingProfileGramPrice[];
};

export function useTemplateShippingProfiles(
  params: FetchTemplateShippingProfilesParams
) {
  return useImmutableSWR<TemplateShippingProfile[]>(
    `/templates/${params.id}/shippingProfiles`,
    (_, params) => fetchTemplateShippingProfiles(params)
  );
}

export function fetchImageTags() {
  return fetcher({
    url: `${LOCALAPI}/user/images/tags`,
  }).then((res) => {
    return res["image_tags"];
  });
}

export type ImageTag = {
  id: number;
  type: string;
  name: string;
};

export function useImageTags() {
  return useImmutableSWR<ImageTag[]>("user/images/tags", fetchImageTags);
}
