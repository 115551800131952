export default {
  styleOverrides: {
    badge: {
      height: 24,
      width: 24,
      border: "2px solid",
      borderRadius: 12,
    },
    anchorOriginTopRightCircular: {
      transform: "scale(1) translate(calc(50% + -2px), calc(-50% + 2px))",
    },
  },
};
