declare module "@mui/material/Button/Button" {
  interface ButtonPropsVariantOverrides {
    link: true;
  }
}

export default {
  defaultProps: {
    // The props to change the default for.
    disableElevation: true, // No more elevation!
    disableRipple: true, // No more ripple!
  },
  variants: [
    {
      props: { variant: "link" },
      style: {
        display: "flex",
        alignItems: "center",
        marginLeft: "auto",
        cursor: "pointer",
        background: "none",
        border: 0,
        boxShadow: "none",
        padding: 0,
        textDecoration: "underline",
        "&:hover": {
          textDecoration: "underline",
          background: "none",
        },
      },
    },
  ],
  styleOverrides: {
    text: {
      // color: 'white',
      // height: 40,
      // padding: "0 20px",
      // boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
    },
    contained: {
      color: "white",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)",
    },
  },
};
