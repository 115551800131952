export const GTMPageView = (url) => {
    // interface PageEventProps {
    //   event: string;
    //   page: string;
    // }
  
    const pageEvent = {
      event: 'pageview',
      page: url,
    };
    //@ts-ignore
    window && window.dataLayer && window.dataLayer.push(pageEvent);
    return pageEvent;
  };
  
  export const GTMEvent = (data) => {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push(data);
  }
  
  export const GTMTransaction = order => {
    let o = order || {}
    // let items = order.line_items.map(v => {
    //   return {
    //     "sku": v.
    //   }
    // })
    GTMEvent({
      'event': 'purchase',
      // 'device': 'desktop',
      'transactionId': o.id,
      // 'transactionAffiliation': 'Acme Clothing',
      'transactionTotal': o.total_price,
      // 'transactionTax': ,
      // 'transactionShipping': 5,
      // 'transactionProducts': [{
      //   'sku': 'DD44',
      //   'name': 'T-Shirt',
      //   'category': 'Apparel',
      //   'price': 11.99,
      //   'quantity': 1
      // },{
      //   'sku': 'AA1243544',
      //   'name': 'Socks',
      //   'category': 'Apparel',
      //   'price': 9.99,
      //   'quantity': 2
      // }]
    })
  }