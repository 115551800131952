import { getProductsDetail } from "@services/dashboard";
import { updateProduct } from "@services/products";
import { call, put, select, takeLatest } from "redux-saga/effects";
import ActionTypes from "../ActionTypes";

function* fetchProductDetailsEffect({ type, payload, options }) {
  try {
    yield put({
      type: ActionTypes.PRODUCT_DETAILS_SET,
      data: { loading: true },
    });
    let data = yield call(getProductsDetail, payload, options);
    data = data.data;
    yield put({
      type: ActionTypes.PRODUCT_DETAILS_SET,
      data: { product: data.product, loading: false },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* updateProductDetailEffect({ type, payload, options }) {
  try {
    yield put({
      type: ActionTypes.PRODUCT_DETAILS_SET,
      data: { saving: true },
    });
    let product = yield select((state) => state.product.product);
    product = {
      id: product.id,
      title: product.title,
      slug: product.slug,
      description: product.description,
      tags: product.tags,
      image_id: product.image_id,
      variants: product.variants.map((item) => ({
        id: item.id,
        price: item.price,
      })),
    };
    let res = yield call(updateProduct, product, options);
    let data = res.data;
    // let productData = data.product
    yield put({
      type: ActionTypes.PRODUCT_DETAILS_SET,
      data: {
        saving: false,
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    // console.log(e)
    yield put({
      type: ActionTypes.PRODUCT_DETAILS_SET,
      data: { data: e, saving: false },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* watch() {
  yield takeLatest(
    ActionTypes.EFFECT_PRODUCT_DETAILS_LOAD,
    fetchProductDetailsEffect
  );
  yield takeLatest(
    ActionTypes.EFFECT_PRODUCT_DETAILS_UPDATE,
    updateProductDetailEffect
  );
}

export default watch;
