import { ThemeOptions } from "@mui/material";
import type {} from "@mui/x-data-grid/themeAugmentation";
import Tip from "./CustomComponents/Tip";
import { default as MuiBadge } from "./MuiBadge";
import { default as MuiButton } from "./MuiButton";
import { default as MuiButtonBase } from "./MuiButtonBase";
import { default as MuiDataGrid } from "./MuiDataGrid";
import { default as MuiDialogActions } from "./MuiDialogActions";
import { default as MuiDivider } from "./MuiDivider";
import { default as MuiFilledInput } from "./MuiFilledInput";
import { default as MuiFormLabel } from "./MuiFormLabel";
import { default as MuiInputLabel } from "./MuiInputLabel";
import { default as MuiLink } from "./MuiLink";
import { default as MuiOutlinedInput } from "./MuiOutlinedInput";
import { default as MuiSelect } from "./MuiSelect";
import { default as MuiSkeleton } from "./MuiSkeleton";
import { default as MuiStepLabel } from "./MuiStepLabel";
import { default as MuiSwitch } from "./MuiSwitch";
import { default as MuiTooltip } from "./MuiTooltip";

export default <ThemeOptions["components"]>{
  MuiBadge,
  MuiButton,
  MuiButtonBase,
  MuiDataGrid,
  MuiDialogActions,
  MuiDivider,
  MuiFilledInput,
  MuiFormLabel,
  MuiInputLabel,
  MuiLink,
  MuiOutlinedInput,
  MuiSelect,
  MuiSkeleton,
  MuiStepLabel,
  MuiSwitch,
  MuiTooltip,

  // custom components
  Tip,
};
