import actionTypes from '../ActionTypes'

function product(state = false , action) {
  switch (action.type) {
    case actionTypes.PRODUCT_DETAILS_SET: {
      return {...state, ...action.data}
    }

    case actionTypes.PRODUCT_DETAILS_UPDATE: {
      let product = {...state.product, ...action.data}
      return {...state, ...{product}}
    }
    
    default:
      return state
  }
}

export default product
