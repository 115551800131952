export default {
  isActive: false,
  step: 0,
  use_same_address: true,
  credit_card: {
    billing_first_name: '',
    billing_last_name: '',
    card_number: '',
    expiry: '',
    cvc: '',
  },
  shipping_address: {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    address: '',
    address2: '',
    zip: '',
    city: '',
    province: '',
    country: ''
  },
  billing_address: {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    address: '',
    address2: '',
    zip: '',
    city: '',
    province: '',
    country: ''
  }
}