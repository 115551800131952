import products from './products'
import dashboard from './dashboard'
import template from './template'
import admin from './admin'
import app from './app'
import { all, fork } from 'redux-saga/effects'

export default function* root() {
  yield all([
    fork(products), fork(dashboard), fork(template), fork(app), fork(admin)
  ])
}
