import app from "./app";
import checkout from "./checkout";
import common from "./common";
import dashboard from "./dashboard";
import mobileSearchInput from "./mobileSearchInput";
import overlay from "./overlay";
import product from "./product";
import productList from "./productList";
import shareToSociety from "./shareToSociety";
import shippingPrices from "./shippingPrices";
import shoppingCart from "./shoppingCart";
import template from "./template";
import templates from "./templates";

export default {
  // example,
  common,
  checkout,
  shoppingCart,
  productList,
  // productDetail,
  // productTemplate,
  overlay,
  mobileSearchInput,
  shareToSociety,
  templates,
  app,
  product,
  dashboard,
  template,
  shippingPrices,
};
