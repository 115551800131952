import { alpha } from "@mui/material/styles";

export default {
  styleOverrides: {
    tooltip: {
      backgroundColor: alpha("#000000", 0.9),
      padding: 8,
    },
    arrow: {
      color: alpha("#000000", 0.9),
    },
  },
};
