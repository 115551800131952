import fetcher from "lib/fetcher";
import useSWRImmutable from "swr/immutable";
import request from "../utils/request";

let LOCALAPI = `${
  typeof window === undefined
    ? process.env.SERVER
    : process.env.NEXT_PUBLIC_CLIENT_SERVER
}/${process.env.NEXT_PUBLIC_API_PREFIX}`;

export function appInitialLoad(options = {}) {
  return fetcher({
    url: `${LOCALAPI}/app/init`,
    method: "GET",
    ...options,
  });
}

export function postEmailCheck(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/auth/email/check`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function postLogin(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/auth/login`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function postComplete(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/complete`,
    method: "put",
    data: param,
    ...options,
  });
  return response;
}

export function postGoogleLogin(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/auth/login/google`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function postSendResetEmail(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/auth/email/reset`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function postResetPassword(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/auth/reset`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function getCheckToken(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/auth/email/token`,
    method: "get",
    data: param,
    ...options,
  });
  return response;
}

export function getIsLogin(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/auth/islogin`,
    method: "get",
    data: param,
    ...options,
  });
  return response;
}

export function postRegister(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/auth/register`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function getPresignedUrl(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/signing/server`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function logout() {
  let response = request({
    url: `${LOCALAPI}/auth/logout`,
    method: "get",
    // ...options
  });

  return response;
}

export function connectEtsy(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/platform/etsy/connect`,
    method: "get",
    ...options,
  });
  return response;
}

export function connectShopify(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/platform/shopify/connect`,
    method: "post",
    data: { referral: "client", ...param },
    ...options,
  });
  return response;
}

export function connectWoocommerce(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/platform/woocommerce/authorize`,
    method: "post",
    data: { referral: "client", ...param },
    ...options,
  });
  return response;
}

export function checkoutSuccessed(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/paypal/checkout/success/${param.tid}`,
    method: "get",
    data: param,
    ...options,
  });
  return response;
}

export function getShopifyReview(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/shopify_review`,
    method: "get",
    ...options,
  });
  return response;
}

export function postShopifyReview(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/shopify_review`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function getShippingProfiles(param, options = {}) {
  let response = request({
    url:
      `${LOCALAPI}/shipping_profiles` +
      (param && param.id ? "/" + param.id : ""),
    method: "get",
    ...options,
  });
  return response;
}

export function getShoppingCart(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/cart`,
    method: "get",
    ...options,
  });
  return response;
}

export const COUNTRIES_KEY = "/countries";

export function getCountries() {
  return fetcher({ url: `${LOCALAPI}${COUNTRIES_KEY}` }).then((res) => {
    return res["countries"];
  });
}

export type Country = {
  id: number;
  zone_id: number;
  chinaarea: string;
  country: string;
  country_code: string;
  description: string;
  isoNumeric: string;
  ispAlpha3: string;
  state_code: string;
  timezone: string;
};

export default function useCountries() {
  return useSWRImmutable<Country[]>(COUNTRIES_KEY, getCountries);
}
