import { lighten } from "@mui/material";
import { palette } from "../palette";

export default {
  styleOverrides: {
    root: {
      fontSize: 14,
      border: "1px solid",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      backgroundColor: "#ffffff",
      "&:hover": {
        backgroundColor: "#ffffff",
      },
      "&.Mui-error": {
        borderColor: "#ff5555 !important",
        boxShadow: `${lighten("#ff5555", 0.8)} 0px 0px 0px 2px`,
      },
      "&.Mui-focused": {
        "&:not(.Mui-error)": {
          boxShadow: `${lighten(palette.primary.main, 0.8)} 0px 0px 0px 2px`,
          borderColor: palette.primary.main,
        },
      },
    },
    inputSizeSmall: {
      paddingTop: 20,
      paddingBottom: 6,
    },
    input: {
      padding: "20px 10px 6px",
      borderRadius: 3,
      // border: "1px solid rgba(0, 0, 0, 0.23)",
      WebkitAppearance: "none",
      background: "#ffffff",
      fontSize: 16,
      height: 16,
      // "&:hover": {
      //   borderColor: "#40354e",
      // },
      // "&:focus": {
      //   borderColor: "#40354e",
      // },
    },
    underline: {
      "&:after": {
        borderBottom: "none",
      },
      "&:before": {
        borderBottom: "none !important",
      },
    },
  },
};
