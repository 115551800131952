const MuiDataGrid = {
  styleOverrides: {
    root: {
      border: "none",
    },
    columnHeader: {
      outline: "none !important",
    },
    cell: {
      outline: "none !important",
    },
    overlay: {
      zIndex: 2,
    },
    columnHeaderTitle: {
      fontWeight: 600,
    },
  },
};

export default MuiDataGrid;
