export default {
  isLoading: false,
  id: 1,
  country_id: 212,
  country: "United States",
  country_code: "US",
  shipping_profile_id: 1,
  shipping_profiles: [],
  freights: [],
  price: 0,
  discount: 0,
  freight: 0,
  subtotal: 0,
  total_price: 0,
  recommends: [],
  countries: [],
  count: 0,
  line_items: [
    // {
    //   "id": 1,
    //   "product_id": 68459,
    //   "template_id": 326,
    //   "product_variant_id": 436430,
    //   "template_variant_id": 662,
    //   "title": "Women's Cotton Stretch CrewNeck T-Shirt",
    //   "image": {
    //     "id": 254150,
    //     "title": null,
    //     "type": "EXHIBITION",
    //     "src": "https://www.printy6.com/images/product/64e0c16e-b502-11ea-b7cf-8c8590095c10.png",
    //     "thumb_src": "https://www.printy6.com/images/product/64e0c16e-b502-11ea-b7cf-8c8590095c10.png?d=200x200",
    //     "alt": null,
    //     "position": 1,
    //     "created_at": "2020-06-23T11:34:17+08:00",
    //     "updated_at": "2020-06-23T11:34:17+08:00"
    //   },
    //   "quantity": 1,
    //   "price": null,
    //   "discount": 3.89,
    //   "freight": 10.25,
    //   "subtotal": 31.11,
    //   "total_price": 41.36,
    //   "option1": {
    //     "id": 2402,
    //     "name": "Size",
    //     "value": "XS",
    //     "code": null,
    //     "src": null,
    //     "position": "1"
    //   },
    //   "option2": {
    //     "id": 2408,
    //     "name": "Color",
    //     "value": "White",
    //     "code": "#ffffff",
    //     "src": null,
    //     "position": "1"
    //   },
    //   "option3": null,
    //   "created_at": "2020-07-04T15:02:03+08:00",
    //   "updated_at": "2020-07-04T15:02:03+08:00"
    // }
  ],
};
