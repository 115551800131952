export default {
  count: 2,
  page: 1,
  totalPage: 1,
  data: [{
    id: 1,
    mainImage: '',
    title: 'Classic T-Shirt',
    price: 22.95,
  }]
}
