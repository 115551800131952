import actionTypes from '../ActionTypes'

function templates(state = false , action) {
  switch (action.type) {
    case actionTypes.TEMPLATES_LIST_SET:
      return {...state, ...action.data}
    default:
      return state
  }
}

export default templates
