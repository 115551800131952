import actionTypes from '../ActionTypes'

function overlay(state = false, action) {
  switch (action.type) {
    case actionTypes.UI_OVERLAY_SWITCH:
      return Object.assign({}, state, {
        isActive: action.isActive,
      })
    default:
      return state
  }
}

export default overlay
