import { getShippingProfiles } from "@services/app";
import {
  getBillingAddress,
  getBrandService,
  getCreditCards,
  getDashboardHome,
  getImageLibrary,
  getIntegrations,
  getInvoices,
  getOrders,
  getOrdersDetail,
  getProducts,
  getProductsDetail,
  getUserShippingProfiles,
  getWarehouseInventories,
  getWarehouseOrderList,
} from "@services/dashboard";
import { updateProduct } from "@services/products";
import { call, put, select, takeLatest } from "redux-saga/effects";
import ActionTypes from "../ActionTypes";
import { initDashboard } from "../actions/dashboard";

function* fetchLibraryEffect({ type, payload, options }) {
  try {
    yield put({
      type: ActionTypes.DASHBOARD_LIBRARY_SET,
      data: { loading: true },
    });

    const { page, size, keywords } = payload;

    let data = yield call(
      getImageLibrary,
      { page: page || 1, size: size || 48, keywords: keywords || "" },
      options
    );
    yield put({
      type: ActionTypes.DASHBOARD_LIBRARY_SET,
      data: {
        list: data.images,
        loading: false,
        page: data.pagination.current_page,
        size: data.pagination.per_page,
        count: data.pagination.total,
        keywords: keywords ? keywords : "",
      },
    });

    if (payload.resolve) {
      payload.resolve();
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_LIBRARY_SET,
      data: { loading: false },
    });
    if (payload.reject) {
      payload.reject();
    }
  }
}

function* fetchOrdersEffect({ type, payload, options }) {
  try {
    const { page, size, keywords, status, date, from, to } = payload;
    const filter = {
      status: status ? status : "ALL",
      date: date ? date : "",
    };

    yield put({
      type: ActionTypes.DASHBOARD_ORDERS_SET,
      data: { loading: true, filter: filter },
    });
    let data = yield call(
      getOrders,
      { page, size, keywords, status, date, from, to },
      options
    );
    data = data.data;
    yield put({
      type: ActionTypes.DASHBOARD_ORDERS_SET,
      data: {
        list: data.orders,
        loading: false,
        page: parseInt(data.meta.current_page),
        size: parseInt(data.meta.per_page),
        count: parseInt(data.meta.total),
      },
    });
    if (payload.resolve) {
      payload.resolve();
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_ORDERS_SET,
      data: { loading: false },
    });
    if (payload.reject) {
      payload.reject();
    }
  }
}

function* fetchProductsEffect({ type, payload, options }) {
  try {
    const { page, size, keywords, fields } = payload;

    yield put({
      type: ActionTypes.DASHBOARD_PRODUCTS_SET,
      data: { loading: true },
    });
    let data = yield call(
      getProducts,
      { page, size, keywords, fields },
      options
    );
    data = data.data;
    yield put({
      type: ActionTypes.DASHBOARD_PRODUCTS_SET,
      data: {
        list: data.products,
        loading: false,
        page: parseInt(data.meta.current_page),
        size: parseInt(data.meta.per_page),
        count: parseInt(data.meta.total),
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_PRODUCTS_SET,
      data: { loading: false },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchOrdersDetailEffect({ type, payload, options }) {
  try {
    const { id } = payload;

    yield put({
      type: ActionTypes.DASHBOARD_ORDERS_DETAIL_SET,
      data: { loading: true },
    });
    let data = yield call(getOrdersDetail, { id }, options);
    data = data.data;
    yield put({
      type: ActionTypes.DASHBOARD_ORDERS_DETAIL_SET,
      data: {
        ...data,
        loading: false,
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_ORDERS_DETAIL_SET,
      data: { data: e, loading: false },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchProductsDetailEffect({ type, payload, options }) {
  try {
    const { id } = payload;

    yield put({
      type: ActionTypes.DASHBOARD_PRODUCTS_DETAIL_SET,
      data: { loading: true },
    });
    let data = yield call(getProductsDetail, { id }, options);
    data = data.data;
    // let productData = data.product
    yield put({
      type: ActionTypes.DASHBOARD_PRODUCTS_DETAIL_SET,
      data: {
        ...data,
        loading: false,
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_PRODUCTS_DETAIL_SET,
      data: { data: e, loading: false },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* updateProductsDetailEffect({ type, payload, options }) {
  try {
    yield put({
      type: ActionTypes.DASHBOARD_PRODUCTS_DETAIL_SET,
      data: { saving: true },
    });
    let product = yield select(
      (state) => state.dashboard.productsDetail.product
    );

    product = {
      id: product.id,
      title: product.title,
      slug: product.slug ?? null,
      description: product.description,
      tags: product.tags,
      image_id: product.image_id,
      variants: product.variants.map((item) => ({
        id: item.id,
        price: item.price,
      })),
    };

    let data = yield call(updateProduct, product, options);
    data = data.data;
    // let productData = data.product
    yield put({
      type: ActionTypes.DASHBOARD_PRODUCTS_DETAIL_SET,
      data: {
        saving: false,
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_PRODUCTS_DETAIL_SET,
      data: { data: e, saving: false },
    });

    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchIntegrationsEffect({ type, payload, options }) {
  try {
    yield put({
      type: ActionTypes.DASHBOARD_INTEGRATIONS_SET,
      data: { loading: true },
    });
    let data = yield call(getIntegrations, {}, options);
    data = data.data;
    yield put({
      type: ActionTypes.DASHBOARD_INTEGRATIONS_SET,
      data: {
        platforms: data.platforms,
        loading: false,
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_INTEGRATIONS_SET,
      data: { loading: false },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchDashboardHomeData({ type, payload, options }) {
  try {
    yield put({
      type: ActionTypes.DASHBOARD_HOME_DATA_SET,
      data: { loading: true },
    });
    let data = yield call(getDashboardHome, payload, options);
    data = data.data;
    yield put({
      type: ActionTypes.DASHBOARD_HOME_DATA_SET,
      data: {
        ...data,
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_HOME_DATA_SET,
      data: { loading: false },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchDashboardCards({ type, payload, options }) {
  try {
    yield put({
      type: ActionTypes.DASHBOARD_CARD_SET,
      data: { loading: true },
    });
    let data = yield call(getCreditCards, payload, options);
    data = data.data;
    yield put({
      type: ActionTypes.DASHBOARD_CARD_SET,
      data: {
        creditCards: data.credit_cards,
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_CARD_SET,
      data: { loading: false },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchShippingProfilesEffect({ type, payload, options }) {
  try {
    yield put({
      type: ActionTypes.DASHBOARD_SHIPPING_SET,
      data: { loading: true },
    });
    let data = yield call(getUserShippingProfiles, {}, options);
    data = data.data;
    if (data["shipping_profiles"]) {
      let arr = {
        shipping_profiles: data["shipping_profiles"],
        loading: false,
      };
      yield put({
        type: ActionTypes.DASHBOARD_SHIPPING_SET,
        data: {
          ...arr,
        },
      });
    }
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_SHIPPING_SET,
      data: { loading: false },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchShippingProfileDetailEffect({ type, payload, options }) {
  try {
    const { id } = payload;

    yield put({
      type: ActionTypes.DASHBOARD_SHIPPING_PROFILE_DETAIL_SET,
      data: { loading: true },
    });
    let data = yield call(getShippingProfiles, payload, options);
    data = data.data;
    if (data["shipping_profile"]) {
      let arr = {
        shipping_profile: data["shipping_profile"],
        loading: false,
      };

      yield put({
        type: ActionTypes.DASHBOARD_SHIPPING_PROFILE_DETAIL_SET,
        data: {
          ...arr,
        },
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_SHIPPING_PROFILE_DETAIL_SET,
      data: { data: e, loading: false },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchInvoices({ type, payload, options }) {
  try {
    const { page, size } = payload;
    yield put({
      type: ActionTypes.DASHBOARD_INVOICES_SET,
      data: { loading: true },
    });
    let arr = {};
    let invoiceRes = yield call(getInvoices, { page, size }, options);
    invoiceRes = invoiceRes.data;
    if (invoiceRes["invoices"]) {
      arr = Object.assign(arr, {
        invoices: invoiceRes["invoices"],
        loading: false,
        page: parseInt(invoiceRes.pagination.current_page),
        size: parseInt(invoiceRes.pagination.per_page),
        count: parseInt(invoiceRes.pagination.total),
      });
    }
    let billingAddressRes = yield call(getBillingAddress, {}, options);
    billingAddressRes = billingAddressRes.data;
    if (billingAddressRes["user_billing_address"]) {
      arr = Object.assign(arr, {
        billingAddress: billingAddressRes["user_billing_address"],
      });
    }
    yield put({
      type: ActionTypes.DASHBOARD_INVOICES_SET,
      data: {
        ...arr,
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.DASHBOARD_INVOICES_SET,
      data: { loading: false },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchWarehouseInventoryList({ type, payload, options }) {
  let key = null;

  try {
    const { page, size, type: inventoryType } = payload;

    key = payload.key;

    yield put(initDashboard(key, { loading: true }));

    let res = yield call(
      getWarehouseInventories,
      { page, size, type: inventoryType },
      options
    );

    const data = res.data;

    yield put(
      initDashboard(key, {
        loading: false,
        list: data.inventories,
        pagination: data.pagination,
      })
    );

    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    if (key) {
      yield put(initDashboard(key, { loading: false }));
    }

    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchWarehouseOrderList({ type, payload, options }) {
  try {
    const { page, size } = payload;

    yield put(initDashboard("warehouseOrderList", { loading: true }));

    let res = yield call(getWarehouseOrderList, { page, size }, options);

    const data = res.data;

    yield put(
      initDashboard("warehouseOrderList", {
        loading: false,
        list: data.orders,
        pagination: data.pagination,
      })
    );

    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put(initDashboard("warehouseOrderList", { loading: false }));

    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchDashboardPackageDetail({ type, payload, options }) {
  try {
    const { id } = payload;

    yield put(initDashboard("packageDetail", { loading: true }));

    let res = yield call(getBrandService, { id }, options);

    const data = res.data;

    yield put(
      initDashboard("packageDetail", {
        loading: false,
        brand_service: data.brand_service,
        products: data.products,
      })
    );

    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put(initDashboard("packageDetail", { loading: false }));

    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* watch() {
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_LIBRARY_LOAD,
    fetchLibraryEffect
  );
  yield takeLatest(ActionTypes.EFFECT_DASHBOARD_ORDERS_LOAD, fetchOrdersEffect);
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_PRODUCTS_LOAD,
    fetchProductsEffect
  );
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_ORDERS_DETAIL_LOAD,
    fetchOrdersDetailEffect
  );
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_PRODUCTS_DETAIL_LOAD,
    fetchProductsDetailEffect
  );
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_PRODUCTS_DETAIL_UPDATE,
    updateProductsDetailEffect
  );
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_INTEGRATIONS_LOAD,
    fetchIntegrationsEffect
  );
  yield takeLatest(ActionTypes.EFFECT_HOME_LOAD, fetchDashboardHomeData);
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_CARDS_LOAD,
    fetchDashboardCards
  );
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_SHIPPING_PROFILES_LOAD,
    fetchShippingProfilesEffect
  );
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_SHIPPING_PROFILES_DETAIL_LOAD,
    fetchShippingProfileDetailEffect
  );
  yield takeLatest(ActionTypes.EFFECT_DASHBOARD_INVOICES_LOAD, fetchInvoices);
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_WAREHOUSE_INVENTORY_LIST_LOAD,
    fetchWarehouseInventoryList
  );
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_WAREHOUSE_ORDER_LIST_LOAD,
    fetchWarehouseOrderList
  );
  yield takeLatest(
    ActionTypes.EFFECT_DASHBOARD_PACKAGE_DETAIL_LOAD,
    fetchDashboardPackageDetail
  );
}

export default watch;
