import { GTMPageView } from "@utils/gtm";
import { LogoJsonLd, SocialProfileJsonLd } from "next-seo";
import App from "next/app";
import Head from "next/head";
import Router from "next/router";
import NProgress from "nextjs-progressbar";
import React from "react";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import ComponentProvider from "../lib/ComponentProvider";
import withReduxStore from "../lib/with-redux-store";
import MuiThemeProvider from "../theme";

import "../lib/global.css";

class MyApp extends App {
  componentDidMount() {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    // GTMPageView
    if (!window.GTM_INITIALIZED) {
      // console.log("Init GTM");
      const tagManagerArgs = {
        gtmId: "GTM-P6N6PTJ",
      };
      TagManager.initialize(tagManagerArgs);
      window.GTM_INITIALIZED = true;
    }

    const handleRouteChange = (url) => GTMPageView(url);
    Router.events.on("routeChangeComplete", handleRouteChange);
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props;

    return (
      <React.Fragment>
        <NProgress
          color="#3D9970"
          startPosition={0.3}
          stopDelayMs={200}
          height={2}
          showOnShallow={true}
          options={{
            showSpinner: false,
          }}
        />
        <Head>
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <SocialProfileJsonLd
            type="Organization"
            name="Printy6"
            url="http://www.printy6.com"
            sameAs={["http://www.facebook.com/printy6"]}
          />
          <LogoJsonLd
            logo="https://www.printy6.com/logo.svg"
            url="http://www.printy6.com"
          />
        </Head>
        <Provider store={reduxStore}>
          <MuiThemeProvider>
            <ComponentProvider>
              <Component {...pageProps} />
            </ComponentProvider>
          </MuiThemeProvider>
        </Provider>
      </React.Fragment>
    );
  }
}

export default withReduxStore(MyApp);
