import { ThemeOptions } from "@mui/material";

declare module "@mui/material/styles/createTypography" {
  interface TypographyOptions {
    fontWeightSemiBold?: number;
    fontWeightExtraBold?: number;
  }

  interface Typography {
    fontWeightSemiBold: number;
    fontWeightExtraBold: number;
  }
}

export default <ThemeOptions["typography"]>{
  // Use the system font instead of the default Roboto font.
  fontSize: 13,
  fontFamily: "'Poppins', sans-serif",
  fontWeightLight: 300,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  h1: {
    fontSize: 64,
    lineHeight: "80px",
    fontWeight: 700,
  },
  h2: {
    fontSize: 48,
    lineHeight: "64px",
    fontWeight: 700,
  },
  h3: {
    fontWeight: 700,
    fontSize: "2.25rem",
    letterSpacing: 0.2,
    lineHeight: 1.2222222222222223,
  },
  h4: {
    fontSize: "1.75rem",
    fontWeight: 700,
    letterSpacing: 0.2,
    lineHeight: 1.5,
  },
  h5: {
    fontSize: "1.5rem",
    lineHeight: "30px",
    fontWeight: 700,
  },
  h6: {
    fontSize: "1.25rem",
    lineHeight: 1.5,
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: "1.125rem",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 1.3333333333333333,
  },
  subtitle2: {
    fontSize: "0.875rem",
    fontWeight: 600,
    lineHeight: 1.57,
  },
  body1: {
    fontSize: "1rem",
    letterSpacing: 0,
    lineHeight: 1.5,
    fontWeight: 400,
  },
  body2: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  caption: {
    fontSize: 12,
    // lineHeight: '18px',
    lineHeight: "16px",
    fontWeight: 400,
  },
  overline: {
    fontSize: 12,
    lineHeight: "18px",
    fontWeight: 700,
    textSpacing: 1.2,
  },
  button: {
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 700,
    textTransform: "unset",
  },
};
