import fetcher from "lib/fetcher";
import request from "utils/request";

export const LOCALAPI = `${
  typeof window === undefined
    ? process.env.SERVER
    : process.env.NEXT_PUBLIC_CLIENT_SERVER
}/${process.env.NEXT_PUBLIC_API_PREFIX}`;

export function getImageLibrary(param, options = {}) {
  return fetcher({
    url: `${LOCALAPI}/user/images`,
    method: "GET",
    data: param,
    ...options,
  });

  // let response = fetcher({
  //   url: `${LOCALAPI}/user/images`,
  //   method: "get",
  //   data: param,
  //   ...options,
  // });
  // return response;
}

export function fetchFavoriteIds(param?: any, options?: any) {
  return fetcher({
    url: `${LOCALAPI}/user/images/favorites`,
    method: "GET",
    ...options,
  }).then((res) => {
    return res.favorite_image_ids;
  });
}

export function postImageFavorite(param, options?: any) {
  return fetcher({
    url: `${LOCALAPI}/user/images/favorites`,
    method: "POST",
    data: param,
    ...options,
  }).then((res) => {
    return res.favorite_image_ids;
  });
}

export function getOrders(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/orders`,
    method: "get",
    data: param,
    ...options,
  });
  return response;
}

export function updateOrder(param) {
  // let response = request
  let response = request({
    url: `${LOCALAPI}/orders/${param.id}`,
    method: "put",
    data: param,
  });
  return response;
}

export function cancelOrder(param) {
  // let response = request
  let response = request({
    url: `${LOCALAPI}/orders/${param.id}/cancel`,
    method: "put",
    data: param,
  });
  return response;
}

export type GetProductsQuery = {
  page?: number;
  size?: number;
  fields?: string;
  keywords?: string;
};

export function getProducts(query: GetProductsQuery, options = {}) {
  let response = request({
    url: `${LOCALAPI}/products`,
    method: "get",
    data: query,
    ...options,
  });

  return response;
}

export function deleteProducts(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/products`,
    method: "delete",
    data: param,
    ...options,
  });
  return response;
}

export function getOrdersDetail(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/orders/${param.id}`,
    method: "get",
    ...options,
  });
  return response;
}

export function getProductsDetail(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/products/${param.id}`,
    method: "get",
    ...options,
  });
  return response;
}

export function postImages(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/images`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function deleteImages(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/images`,
    method: "delete",
    data: param,
    ...options,
  });
  return response;
}

export function getIntegrations(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/platforms`,
    method: "get",
    data: param,
    ...options,
  });
  return response;
}

export function getDashboardHome(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/dashboard`,
    method: "get",
    data: param,
    ...options,
  });
  return response;
}

export function getCreditCards(param, options = {}) {
  let str = "";
  if (Object.keys(param).length > 0) {
    str = "?";
    let index = 0;
    for (let key in param) {
      if (index !== 0) {
        str += "&";
      }
      str += key + "=" + param[key];
    }
  }
  let response = request({
    url: `${LOCALAPI}/user/cards` + str,
    method: "get",
    ...options,
  });

  return response;
}

export function getPrefilledUserBillingInfo(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/cards/billing/info`,
    method: "get",
    ...options,
  });

  return response;
}

export function removePlatforms(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/platforms/${param.id}`,
    method: "delete",
    ...options,
  });
  return response;
}

export function getUserShippingProfiles(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/shipping/profiles`,
    method: "get",
    ...options,
  });
  return response;
}

export function putShippingProfile(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/shipping/profiles/${param.id}`,
    method: "put",
    data: param,
    ...options,
  });
  return response;
}

export function getShippingTemplateSetRates(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/shipping/profiles/template_set_rates`,
    method: "get",
    data: param,
    ...options,
  });
  return response;
}

export function getShippingTemplateSetRate(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/shipping/profiles/template_set_rates/${param.id}`,
    method: "get",
    ...options,
  });
  return response;
}

export function updateOrderShippingProfile(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/orders/${param.order_id}/shipping_profiles/${param.shipping_profile_id}`,
    method: "put",
    ...options,
  });
  return response;
}

export function getOrderShippingProfiles(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/orders/${param.id}/shipping_profiles`,
    method: "get",
    ...options,
  });
  return response;
}

export function updateUserSetting(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/setting`,
    method: "put",
    data: param,
    ...options,
  });
  return response;
}

export function getCards(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/cards`,
    method: "get",
    ...options,
  });
  return response;
}

export function deleteCard(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/cards/${param.id}`,
    method: "delete",
    ...options,
  });
  return response;
}

export function updateCard(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/cards/${param.id}`,
    method: "put",
    data: param,
    ...options,
  });
  return response;
}

export function getVerifyInfo(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/cards/${param.id}/verify`,
    method: "get",
    ...options,
  });
  return response;
}

export function checkout(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/invoice/paypal`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function createInvoice(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/invoices`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function createCreditcardInvoice(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/invoice/creditcard`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function creditcardInvoiceSuccess(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/invoice/creditcard/success`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function createCard(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/cards`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function quickpay(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/invoice/quickpay`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function stripeSetUp(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/cards/setup`,
    method: "get",
    ...options,
  });
  return response;
}

export function checkInvoice(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/invoices/${param.transaction_id}/check`,
    method: "post",
    data: param,
    ...options,
  });
  return response;
}

export function getInvoice(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/invoices/${param.id}`,
    method: "get",
    ...options,
  });
  return response;
}

export function getInvoices(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/invoices`,
    method: "get",
    data: param,
    ...options,
  });
  return response;
}

export function getBillingAddress(param, options = {}) {
  return request({
    url: `${LOCALAPI}/user/billingAddress`,
    method: "get",
    data: param,
    ...options,
  });
}

export function createBillingAddresses(param, options = {}) {
  return request({
    url: `${LOCALAPI}/user/billingAddresses`,
    method: "post",
    data: param,
    ...options,
  });
}

export function updateBillingAddresses(param, options = {}) {
  return request({
    url: `${LOCALAPI}/user/billingAddresses/${param.id}`,
    method: "put",
    data: param,
    ...options,
  });
}

export function postWarehouseOrder(param = {}, options = {}) {
  let response = request({
    url: `${LOCALAPI}/warehouse/orders`,
    method: "post",
    data: param,
    ...options,
  });

  return response;
}

export function getWarehouseOrderList(param = {}, options = {}) {
  let response = request({
    url: `${LOCALAPI}/warehouse/orders`,
    method: "get",
    data: param,
    ...options,
  });

  return response;
}

export function getWarehouseInventories(param = {}, options = {}) {
  let response = request({
    url: `${LOCALAPI}/warehouse/inventories`,
    method: "get",
    data: param,
    ...options,
  });

  return response;
}

export function getBrandServices(param = {}, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/brandServices`,
    method: "get",
    data: param,
    ...options,
  });

  return response;
}

export function getBrandService(param, options = {}) {
  const { id, ...other } = param;
  let response = request({
    url: `${LOCALAPI}/user/brandServices/${id}`,
    method: "get",
    data: other,
    ...options,
  });

  return response;
}

export function getBrandServiceTemplates(param, options = {}) {
  const { id, ...other } = param;
  let response = request({
    url: `${LOCALAPI}/user/brandServices/${id}/templates`,
    method: "get",
    data: other,
    ...options,
  });

  return response;
}

export type UpdateBrandServiceQuery = {
  id: number;
  inventory_id?: number;
};

export function updateBrandService(
  param: UpdateBrandServiceQuery,
  options = {}
) {
  const { id, ...other } = param;
  let response = request({
    url: `${LOCALAPI}/user/brandServices/${id}`,
    method: "put",
    data: other,
    ...options,
  });

  return response;
}

export function openWarehouseService() {
  let response = request({
    url: `${LOCALAPI}/warehouse/open`,
    method: "post",
  });

  return response;
}

export function getAddressBooks() {
  let response = request({
    url: `${LOCALAPI}/user/addressBooks`,
    method: "get",
  });

  return response;
}

export function getAddressBook(params, options = {}) {
  const { id, ...others } = params;
  let response = request({
    url: `${LOCALAPI}/user/addressBooks/${id}`,
    method: "get",
    data: others,
    ...options,
  });

  return response;
}

export function createAddressBook(params, options = {}) {
  let response = request({
    url: `${LOCALAPI}/user/addressBooks`,
    method: "post",
    data: params,
    ...options,
  });

  return response;
}

export function updateAddressBook(params, options = {}) {
  const { id, ...others } = params;
  let response = request({
    url: `${LOCALAPI}/user/addressBooks/${id}`,
    method: "put",
    data: others,
    ...options,
  });

  return response;
}

export function deleteAddressBook(params) {
  const { id } = params;
  let response = request({
    url: `${LOCALAPI}/user/addressBooks/${id}`,
    method: "delete",
  });

  return response;
}

export function createOrderReview(params) {
  const { orderId, data } = params;
  let response = request({
    url: `${LOCALAPI}/orders/${orderId}/reviews`,
    method: "post",
    data,
  });

  return response;
}

export function reviewLikes(params) {
  const { reviewId } = params;
  let response = request({
    url: `${LOCALAPI}/reviews/${reviewId}/likes`,
    method: "post",
  });

  return response;
}
