/* global window */
import axios from "axios";
// import qs from 'qs'
// import jsonp from 'jsonp'
import cloneDeep from "lodash.clonedeep";
// import pathToRegexp from 'path-to-regexp'
// import { message } from 'antd'

const fetch = (options) => {
  let {
    method = "get",
    data,
    fetchType,
    responseType = "",
    url,
    headers,
  } = options;

  const cloneData = cloneDeep(data);

  axios.defaults.withCredentials = true;
  switch (method.toLowerCase()) {
    case "get":
      return axios.get(url, {
        params: cloneData,
        headers,
        withCredentials: true,
      });
    case "delete":
      return axios.delete(url, {
        data: cloneData,
      });
    case "post":
      return axios({
        url: url,
        method: method,
        data: cloneData,
        responseType: responseType,
      });
    // return axios.post(url, cloneData)
    case "put":
      return axios.put(url, cloneData);
    case "patch":
      return axios.patch(url, cloneData);
    default:
      return axios(options);
  }
};

export default function request(options) {
  // if (options.url && options.url.indexOf('//') > -1) {
  //   const origin = `${options.url.split('//')[0]}//${options.url.split('//')[1].split('/')[0]}`
  //   if (window.location.origin !== origin) {
  //     if (CORS && CORS.indexOf(origin) > -1) {
  //       options.fetchType = 'CORS'
  //     } else if (YQL && YQL.indexOf(origin) > -1) {
  //       options.fetchType = 'YQL'
  //     } else {
  //         options.fetchType = 'JSONP'
  //     }
  //   }
  // }
  // let result = axios.get('https://www.printy6.com/api/v2/app/init', options.data)
  // let response = result.then(res => {
  //   // console.log(res)
  //   return Promise.resolve({
  //     data: res.data
  //   })
  // })
  // response.then(data => {
  //   console.log(data)
  // })
  // return response
  return fetch(options)
    .then((response) => {
      const { statusText, status } = response;
      // console.log(response.headers['set-cookie'])
      // console.log(response)
      // return
      let data = options.responseType === "blob" ? response : response.data;
      // if (data instanceof Array) {
      //   data = {
      //     list: data,
      //   }
      // }
      // console.log(data)
      // let a = {...data}
      return Promise.resolve({
        success: true,
        message: statusText,
        statusCode: status,
        _cookies: response.headers["set-cookie"],
        data,
      });
    })
    .catch((error) => {
      const { response } = error;
      // console.log(response)
      let msg;
      let statusCode;
      if (response && response instanceof Object) {
        const { data, statusText } = response;
        statusCode = response.status;
        msg = (data && data.error && data.error.message) || statusText;
      } else {
        statusCode = 600;
        msg = error.message || "Network Error";
      }
      // test
      // console.log(error)
      // return error
      // test end
      /* eslint-disable */
      return Promise.reject({
        success: false,
        statusCode,
        message: msg,
        response: response ? response.data : "",
      });
    });
}
