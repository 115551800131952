import actionTypes from '../ActionTypes'
import initialState from '../initialState'

function template(state = initialState.template, action) {
  switch (action.type) {
    case actionTypes.TEMPLATE_INIT_SET:
      return {...state, ...action.data}
    default:
      return state
  }
}

export default template
