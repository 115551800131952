type Method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "HEAD" | "OPTIONS";

interface Params {
  url: string;
  method?: Method;
  wrapper?: string;
  data?: any;
  headers?: HeadersInit;
  other?: any;
  signal?: AbortSignal;
}

export default function fetcher({
  url,
  method = "GET",
  data,
  wrapper,
  headers,
  signal,
  ...other
}: Params) {
  let params: RequestInit = {
    method,
  };

  if (signal) {
    params.signal = signal;
  }

  if (data) {
    if (method === "GET" || method === "HEAD") {
      if (data !== null && typeof data === "object") {
        let params = "";
        let dataArr = [];
        for (const key in data) {
          dataArr.push(key + "=" + data[key]);
        }
        params = dataArr.join("&");
        if (params.length > 0) {
          if (url.indexOf("?") !== -1) {
            url += "&" + params;
          } else {
            url += "?" + params;
          }
        }
      }
    } else {
      if (method === "POST" || method === "PUT" || method === "DELETE") {
        params.headers = {
          "Content-Type": "application/json",
        };
      }
      params.body = JSON.stringify(data);
    }
  }

  if (headers) {
    params.headers = Object.assign(headers, params.headers || {});
  }

  if (other) {
    params = Object.assign(params, other);
  }

  return fetch(url, params)
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
      if (!response.ok) {
        const error = JSON.parse(await response.text());
        throw new Error(
          error && error.message ? error.message : response.statusText
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
