import actionTypes from '../ActionTypes'
import initialState from '../initialState'

function mobileSearchInput(state = initialState.mobileSearchInput, action) {
  switch (action.type) {
    case actionTypes.UI_MOBILE_SEARCH_INPUT:
      return Object.assign({}, state, {
        isActive: action.isActive
      })
    default:
      return state
  }
}

export default mobileSearchInput
