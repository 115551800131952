import actionTypes from '../ActionTypes'
import initialState from '../initialState'

function shareToSociety(state = initialState.shareToSociety, action) {
  switch (action.type) {
    case actionTypes.SHARE_TO_SOCIETY_TRIGGER:
      return Object.assign({}, state, action.data)
    default: return state
  }
}

export default shareToSociety
