import request from "../utils/request";

let LOCALAPI = `${
  typeof window === undefined
    ? process.env.SERVER
    : process.env.NEXT_PUBLIC_CLIENT_SERVER
}/${process.env.NEXT_PUBLIC_API_PREFIX}`;

export function fetchTemplate(param = {}, options = {}) {
  let response = request({
    url: `${LOCALAPI}/templates/${param.id}`,
    method: "get",
  });
  return response;
}

export function fetchShippingProfiles(param = {}, options = {}) {
  let response = request({
    url: `${LOCALAPI}/shipping_profiles`,
    method: "get",
  });
  return response;
}

export function editTemplate(templateId, param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/templates/${templateId}`,
    method: "put",
    data: param,
  });
  return response;
}

export function getOrderShippingProfiles(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/shipping_profiles`,
    method: "get",
  });
  return response;
}

export function createShippingRate(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/shipping_rates`,
    method: "post",
    data: param.data,
  });
  return response;
}

export function editShippingRate(param, options = {}) {
  let response = request({
    url: `${LOCALAPI}/shipping_rates/${param.id}`,
    method: "put",
    data: param.data,
  });
  return response;
}
