// Printy6 产品列表页面store
export default {
  // catalog: 2
  list: [],
  loading: false,
  catalogKey: false,
  page: 1,
  size: 12,
  count: 0,
  hasMore: true,
}
