export default {
  template: {
    id: null,
    template_no: "",
    title: "",
    description: "",
    category: {},
    image: {},
    images: [],
    models: [],
    options: [],
    scenes: [],
    variants: [],
    designs: [],
    recommend: [],
    sizes: [],
  },
  activeColorId: null,
};
