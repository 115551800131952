import actionTypes from '../ActionTypes'

function app(state = false , action) {
  switch (action.type) {
    case actionTypes.APP_INIT: {
      let newState = {...state, ...action.data}
      return newState
    }

    case actionTypes.APP_COUNTRIES_SET: {
      let newState = {...state, ...action.data}
      return newState
    }

    case actionTypes.APP_USER_SET: {
      /* 更新全局app数据中的user对象 */
      let user = {user: {...state.user, ...action.data}}
      let newState = {...state, ...user}
      return newState
    }

    case actionTypes.APP_SHOPIFY_REVIEW: {
      let shopifyReview = {shopify_review: {...state.shopify_review, ...action.data}}
      let newState = {...state, ...shopifyReview}
      return newState
    }
    
    case actionTypes.ENQUEUE_SNACKBAR: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };
    }

    case actionTypes.CLOSE_SNACKBAR: {
      return {
        ...state,
        notifications: state.notifications.map(notification => (
          (action.dismissAll || notification.key === action.key)
            ? { ...notification, dismissed: true }
            : { ...notification }
        )),
      };
    }

    case actionTypes.REMOVE_SNACKBAR: {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key,
        ),
      };
    }

    default:
      return state
  }
}

export default app
