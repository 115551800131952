import { lighten } from "@mui/material/styles";
import { palette } from "../../palette";

export default {
  styleOverrides: {
    root: {
      borderRadius: "4px",
      margin: "auto",
      padding: "16px",
      boxShadow: "none",
    },
  },
  variants: [
    {
      props: { variant: "outlined", color: "alert" },
      style: {
        border: "1px solid #dadce0",
        background: "#ffffee",
      },
    },
    {
      props: { variant: "outlined", color: "success" },
      style: {
        border: "1px solid #dadce0",
        background: lighten(palette.primary.main, 0.8),
      },
    },
  ],
};
