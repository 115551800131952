import actionTypes from "../ActionTypes";
import initialState from "../initialState/dashboard";

function setLibrary(data) {
  return {
    type: actionTypes.DASHBOARD_LIBRARY_SET,
    data: data,
  };
}

function setShipping(data) {
  return {
    type: actionTypes.DASHBOARD_SHIPPING_SET,
    data: data,
  };
}

function setInvoices(data) {
  return {
    type: actionTypes.DASHBOARD_INVOICES_SET,
    data: data,
  };
}

function setShippingProfile(data) {
  return {
    type: actionTypes.DASHBOARD_SHIPPING_PROFILE_DETAIL_SET,
    data: data,
  };
}

function initDashboard(key, data) {
  const legalKeys = Object.keys(initialState);
  if (legalKeys.findIndex((item) => item === key) === -1) {
    throw new Error(`The key \`${key}\` is illegal.`);
  }

  return {
    type: actionTypes.DASHBOARD_INIT,
    payload: {
      key,
      data,
    },
  };
}

export {
  setLibrary,
  setShipping,
  setShippingProfile,
  setInvoices,
  initDashboard,
};
