export default {
  user: false,
  catalog: [],
  countries: [],
  platforms: [],
  notifications: [],
  shopify_review: {
    demand_review: false,
    product_count: 0,
  }
}
