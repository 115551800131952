import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import components from "./components";
import { palette } from "./palette";
import typography from "./typography";

interface Props {
  children: any;
}

const themeOptions = {
  type: "light",
  palette,
  typography,
  components,
};

// @TODO: 类型问题留待解决, palette类型修复
// @ts-ignore
export const theme = createTheme(themeOptions);

const MuiTheme = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MuiTheme;
