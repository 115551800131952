import actionTypes from '../ActionTypes'

function resetPassword(state = false, action) {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_INIT:
      return Object.assign({}, state, action.data)
    default:
      return state
  }
}

export default resetPassword
