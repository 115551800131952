import { lighten, ThemeOptions } from "@mui/material";
import { palette } from "../palette";

export default <ThemeOptions["components"]["MuiOutlinedInput"]>{
  styleOverrides: {
    root: {
      "&.Mui-focused": {
        boxShadow: `${lighten(palette.primary.main, 0.8)} 0px 0px 0px 2px`,
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px !important",
        },
      },
    },
  },
};
