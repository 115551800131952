import actionTypes from '../ActionTypes'
import initialState from '../initialState'

function shippingPrices(state = initialState.shippingPrices, action) {
  switch (action.type) {
    case actionTypes.APP_SHIPPING_PRICES_SET:
      return Object.assign({}, state, action.data)
    default: return state
  }
}

export default shippingPrices
