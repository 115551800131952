import actionTypes from "../ActionTypes";

function dashboard(state = false, action) {
  switch (action.type) {
    case actionTypes.DASHBOARD_LIBRARY_SET: {
      let library = { ...state.library, ...action.data };
      return { ...state, library };
    }

    case actionTypes.DASHBOARD_ORDERS_SET: {
      let orders = { ...state.orders, ...action.data };
      return { ...state, orders };
    }

    case actionTypes.DASHBOARD_PRODUCTS_SET: {
      let products = { ...state.products, ...action.data };
      return { ...state, products };
    }

    case actionTypes.DASHBOARD_ORDERS_DETAIL_SET: {
      let ordersDetail = { ...state.ordersDetail, ...action.data };
      return { ...state, ordersDetail };
    }

    case actionTypes.DASHBOARD_PRODUCTS_DETAIL_SET: {
      let productsDetail = { ...state.productsDetail, ...action.data };
      return { ...state, productsDetail };
    }

    case actionTypes.DASHBOARD_PRODUCTS_DETAIL_UPDATE: {
      // console.log(state)
      let { productsDetail } = state;
      let product = { ...productsDetail.product, ...action.data };
      // console.log(product)
      productsDetail = { ...productsDetail, product, ...{ needSync: true } };
      return { ...state, productsDetail };
    }

    case actionTypes.DASHBOARD_ORDERS_DETAIL_UPDATE: {
      // console.log(state)
      let { ordersDetail } = state;
      let order = { ...ordersDetail.order, ...action.data };
      // console.log(product)
      ordersDetail = { ...ordersDetail, order, ...{ needSync: true } };
      return { ...state, ordersDetail };
    }

    case actionTypes.DASHBOARD_INTEGRATIONS_SET: {
      let integrations = { ...state.integrations, ...action.data };
      return { ...state, integrations };
    }

    case actionTypes.DASHBOARD_HOME_DATA_SET: {
      let home = { ...state.home, ...action.data };
      return { ...state, home };
    }

    case actionTypes.DASHBOARD_SHIPPING_SET: {
      let shipping = { ...state.shipping, ...action.data };
      return { ...state, shipping };
    }

    case actionTypes.DASHBOARD_INVOICES_SET: {
      let invoices = { ...state.invoices, ...action.data };
      return { ...state, invoices };
    }

    case actionTypes.DASHBOARD_SHIPPING_PROFILE_DETAIL_SET: {
      let shippingProfile = { ...state.shippingProfile, ...action.data };
      return { ...state, shippingProfile };
    }

    case actionTypes.DASHBOARD_CARD_SET: {
      let payment = { ...state.payment, ...action.data };
      return { ...state, payment };
    }

    case actionTypes.DASHBOARD_INIT: {
      const { key, data } = action.payload;
      return { ...state, ...{ [key]: { ...state[key], ...data } } };
    }

    default:
      return state;
  }
}

export default dashboard;
