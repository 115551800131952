import actionTypes from '../ActionTypes'
import initialState from '../initialState'

function checkout(state = initialState.checkout, action) {
  switch (action.type) {
    case actionTypes.CHECKOUT_TRIGGER:
      return Object.assign({}, state, action.data)
    case actionTypes.CHECKOUT_SWITCH_STEP:
      return Object.assign({}, state, {
        step:  action.step
      })
    case actionTypes.CHECKOUT_USE_SAME_ADDRESS:
      return Object.assign({}, state, {
        use_same_address:  action.data
      })
    case actionTypes.CHECKOUT_CHANGE_SHIPPING_ADDRESS:
      return Object.assign({}, state, {
        shipping_address:  action.data
      })
    case actionTypes.CHECKOUT_CHANGE_BILLING_ADDRESS:
      return Object.assign({}, state, {
        billing_address:  action.data
      })
    case actionTypes.CHECKOUT_CHANGE_CREDIT_CARD:
      return Object.assign({}, state, {
        credit_card:  action.data
      })
    default: return state
  }
}

export default checkout
