import { grey } from "@mui/material/colors";
import { lighten } from "@mui/material/styles";
import { palette } from "../palette";

export default {
  styleOverrides: {
    track: {
      borderRadius: 26 / 2,
      backgroundColor: lighten(palette.primary.main, 0.7),
      opacity: 1,
      transition: "background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      // transition: theme.transitions.create(["background-color"], {
      //   duration: 500,
      // }),
    },
    root: {
      width: 42,
      height: 26,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
      },
      "&.MuiSwitch-sizeSmall": {
        width: 36,
        height: 20,
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          width: "16px important",
          height: "16px important",
        },
      },
    },
    switchBase: {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#ffffff",
        "& + .MuiSwitch-track": {
          // backgroundColor: "#000123",
          backgroundColor: palette.primary.main,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: palette.primary.main,
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: palette.mode === "light" ? grey[100] : grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    thumb: {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
  },
};
