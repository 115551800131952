import actionTypes from '../ActionTypes'
import initialState from '../initialState'

function shoppingCart(state = initialState.shoppingCart, action) {
  switch (action.type) {
    case actionTypes.SHOPPING_CART_EDIT:
      // if(action.data.freights && action.data.freights.length > 0) {
      //   let arr = [];
      //   for (var i = 0; i < action.data.freights.length; i++) {
      //     arr.push({
      //       id: action.data.freights[i].shipping_profile_id,
      //       value: 'US$' + action.data.freights[i].freight + ' (' + action.data.freights[i].shipping_profile_name + ')'
      //     });
      //   }
      //   action.data.freights = arr;
      // }
      return Object.assign({}, state, action.data)
    // case actionTypes.SHOPPING_CART_EDIT_ITEM:
    //   return Object.assign({}, state, {
    //     list: action.data
    //   })
    default: return state
  }
}

export default shoppingCart;
