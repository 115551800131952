import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";

const ComponentProvider = (porps) => {
  const { children } = porps;
  return (
    <ConfirmProvider
      defaultOptions={{
        cancellationButtonProps: {
          color: "inherit",
        },
        confirmationButtonProps: {
          variant: "contained",
        },
        confirmationText: "Confirm",
      }}
    >
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {children}
      </SnackbarProvider>
    </ConfirmProvider>
  );
};

export default ComponentProvider;
