import { fetchTemplate, fetchTemplates } from "@services/template";
import { call, put, takeLatest } from "redux-saga/effects";
import ActionTypes from "../ActionTypes";

function* fetchTemplateEffect({ type, payload, options }) {
  try {
    yield put({ type: ActionTypes.TEMPLATE_INIT_SET, data: { loading: true } });
    let template = yield call(fetchTemplate, payload, options);

    yield put({
      type: ActionTypes.TEMPLATE_INIT_SET,
      data: {
        template: template,
        activeColorId:
          template.colors.length > 0 ? template.colors[0].id : null,
      },
    });

    if (payload.resolve) {
      payload.resolve(template);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.TEMPLATE_INIT_SET,
      data: { loading: false },
    });
    if (payload.reject) {
      payload.reject();
    }
  }
}

function* fetchTemplateCustomizerEffect({ type, payload, options }) {
  // try {
  let data = yield call(fetchTemplate, { id: payload.id }, options);
  data = data.data.template;

  if (payload.resolve) {
    payload.resolve(data);
  }

  // } catch (e) {
  //   yield put({type: ActionTypes.TEMPLATE_INIT_SET, data: {loading: false}})
  //   if(payload.reject) {
  //     payload.reject()
  //   }
  // }
}

function* fetchTemplatesEffect({ type, payload, options }) {
  try {
    yield put({
      type: ActionTypes.TEMPLATES_LIST_SET,
      data: { loading: true },
    });
    let data = yield call(fetchTemplates, payload, options);
    data = data.data;
    yield put({
      type: ActionTypes.TEMPLATES_LIST_SET,
      data: {
        list: data.templates,
        catalogKey: payload.category,
        options: payload.options,
        loading: false,
        page: parseInt(data.meta.current_page),
        size: parseInt(data.meta.per_page),
        count: parseInt(data.meta.total),
        hasMore: data.meta.current_page >= data.meta.last_page ? false : true,
      },
    });
  } catch (e) {
    //
  }
}

function* watch() {
  yield takeLatest(ActionTypes.EFFECT_TEMPLATE_LOAD, fetchTemplateEffect);
  yield takeLatest(ActionTypes.EFFECT_TEMPLATES_LOAD, fetchTemplatesEffect);
  yield takeLatest(
    ActionTypes.EFFECT_TEMPLATE_CUSTOMIZER_LOAD,
    fetchTemplateCustomizerEffect
  );
}

export default watch;
