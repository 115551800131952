import actionTypes from '../ActionTypes'

function productList(state = false, action) {
  switch (action.type) {
    case actionTypes.PRODUCT_SET_PRODUCT_LIST_DATA:
      return Object.assign({}, state, action.data)
    default:
      return state
  }
}

export default productList
