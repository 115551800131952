export default {
  styleOverrides: {
    filled: {
      borderRadius: 3,
      "&:hover": {
        backgroundColor: "#ffffff",
      },
      "&:focus": {
        backgroundColor: "#ffffff",
      },
    },
  },
};
