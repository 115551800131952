//产品定制完成以后的发布页面 store
export default {
  // catalog: 2
  product: {},
  loading: false,
  saving: false,
  platforms: {
    shopify: {
      available: true,
      accounts: [{
        id: 12,
        name: 'woestler.myshopify.com',
        published: false,
      }]
    },
    amazon: {
      available: false,
    },
    youtube: {
      available: false,
    },
    ebay: {
      available: false,
    },
    woocommerce: {
      available: false,
    },
    bigcommerce: {
      available: false,
    },
  },
}
