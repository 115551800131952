import { call, put, takeLatest } from "redux-saga/effects";
// import {  } from '@services/dashboard'
import {
  getCountries,
  getShippingProfiles,
  getShopifyReview,
  getShoppingCart,
} from "services/app";
// import { updateProduct } from '@services/products'
import ActionTypes from "../ActionTypes";

function* fetchCountriesEffect({ type, payload = {}, options }) {
  try {
    yield put({ type: ActionTypes.APP_COUNTRIES_SET, data: { loading: true } });
    let data = yield call(getCountries, {}, options);

    yield put({
      type: ActionTypes.APP_COUNTRIES_SET,
      data: {
        ...data,
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.APP_COUNTRIES_SET,
      data: { loading: false },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchShopifyReviewEffect({ type, payload = {}, options }) {
  try {
    let response = yield call(getShopifyReview, {}, options);
    response = response.data;
    let data = {
      demand_review: response.demand_review,
      product_count: response.product_count,
    };
    yield put({
      type: ActionTypes.APP_SHOPIFY_REVIEW,
      data: {
        ...data,
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    yield put({
      type: ActionTypes.APP_SHOPIFY_REVIEW,
      data: {
        demand_review: false,
        product_count: 0,
      },
    });
    if (payload.reject) {
      payload.reject(e);
    }
  }
}

function* fetchShippingPricesEffect({ type, payload = {}, options }) {
  try {
    let data = yield call(getShippingProfiles, {}, options);
    // console.log({
    //   ...data
    // });
    data = data.data;
    yield put({
      type: ActionTypes.APP_SHIPPING_PRICES_SET,
      data: {
        ...data,
      },
    });
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    // console.log(e);
    // yield put({type: ActionTypes.APP_SHIPPING_PRICES_SET, data: {loading: false}})
    // if(payload.reject) {
    //   payload.reject(e)
    // }
  }
}

function* fetchShoppingCartEffect({ type, payload = {}, options }) {
  try {
    let data = yield call(getShoppingCart, {}, options);
    data = data.data;
    if (data.cart) {
      yield put({
        type: ActionTypes.SHOPPING_CART_EDIT,
        data: {
          ...data.cart,
        },
      });
    }
    if (payload.resolve) {
      payload.resolve(data);
    }
  } catch (e) {
    // console.log(e);
    // yield put({type: ActionTypes.APP_SHIPPING_PRICES_SET, data: {loading: false}})
    // if(payload.reject) {
    //   payload.reject(e)
    // }
  }
}

function* watch() {
  yield takeLatest(ActionTypes.EFFECT_SHOPIFY_REVIEW, fetchShopifyReviewEffect);
  yield takeLatest(ActionTypes.EFFECT_APP_COUNTRIES_LOAD, fetchCountriesEffect);
  yield takeLatest(
    ActionTypes.EFFECT_SHIPPING_PRICES_LOAD,
    fetchShippingPricesEffect
  );
  yield takeLatest(ActionTypes.EFFECT_CART_LOAD, fetchShoppingCartEffect);
}

export default watch;
