type Pagination = {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
};

const pagination: Pagination = {
  current_page: 1,
  last_page: 1,
  per_page: 25,
  total: 0,
};

/**
 * The function init pagiantion instant.
 *
 * Demos:
 *
 * - [TEST](https://mui.com/api/button-unstyled/)
 *
 * API:
 *
 * - [TEST API](https://mui.com/api/button-unstyled/)
 */
export const initPagination = (
  current_page: number = 1,
  last_page: number = 1,
  per_page: number = 25,
  total: number = 0
): Pagination => {
  return {
    current_page,
    last_page,
    per_page,
    total,
  };
};

export default pagination;
