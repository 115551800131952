import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import createSagaMiddleware, { END } from "redux-saga";
import actionTypes from "./ActionTypes";
import initialState from "./initialState";
import reducers from "./reducers/index";
import rootSaga from "./sagas";

interface CustomStoreType extends Store {
  runSaga: () => void;
  saga: any;
  stopSaga: () => void;
  execSagaTasks: (arg1, arg2) => void;
}

// REDUCERS
const combinedReducer = combineReducers(reducers);

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const initializeStore = (state) => {
  const sagaMiddleware = createSagaMiddleware();

  let store: CustomStoreType = createStore(
    combinedReducer,
    state,
    bindMiddleware([sagaMiddleware])
  );

  store.runSaga = () => {
    if (store.saga) return;
    store.saga = sagaMiddleware.run(rootSaga);
  };

  store.stopSaga = async () => {
    // Avoid running twice
    if (!store.saga) return;
    store.dispatch(END);
    await store.saga.done;
    store.saga = null;
  };

  store.execSagaTasks = async (isServer, tasks) => {
    // run saga
    store.runSaga();
    // dispatch saga tasks
    tasks(store.dispatch);
    // Stop running and wait for the tasks to be done
    await store.stopSaga();
    // Re-run on client side
    if (!isServer) {
      store.runSaga();
    }
  };

  store.runSaga();

  return store;
};

export type RootState = {
  common: any;
  shoppingCart: any;
  checkout: any;
  productList: any;
  overlay: any;
  mobileSearchInput: any;
  shareToSociety: any;
  templates: any;
  app: any;
  product: any;
  dashboard: any;
  template: any;
  resetPassword: any;
  shippingPrices: any;
};

export { actionTypes, initialState, initializeStore };
