import actionTypes from '../ActionTypes'
import initialState from '../initialState'

function common(state = initialState.common, action) {
  switch (action.type) {
    case actionTypes.COMMON_SET_DEVICE:
      return Object.assign({}, state, action.data)
    default: return state
  }
}

export default common
