import request from "../utils/request";

let LOCALAPI = `${
  typeof window === undefined
    ? process.env.SERVER
    : process.env.NEXT_PUBLIC_CLIENT_SERVER
}/${process.env.NEXT_PUBLIC_API_PREFIX}`;

export function fetchCatalogList() {
  let response = request({
    url: `${LOCALAPI}/catalog/list`,
    method: "get",
  });
  return response;
  // return ['yes']
}

export function getEcwidCategories(params = {}, options = {}) {
  let response = request({
    url: `${LOCALAPI}/platform/ecwid/categories`,
    method: "get",
    data: params,
  });
  return response;
}

export function postAddCart(param = {}, options = {}) {
  let url;
  url = `${LOCALAPI}/products/${param.key}`;

  return request({
    url,
    method: "get",
    // data: param,
    ...options,
  });
}

export function getProductDetail(param = {}, options = {}) {
  let url;
  url = `${LOCALAPI}/products/${param.key}`;

  return request({
    url,
    method: "get",
    // data: param,
    ...options,
  });
}

export function getProductStatus(param = {}, options = {}) {
  let url;
  url = `${LOCALAPI}/products/${param.key}/status`;

  return request({
    url,
    method: "get",
    // data: param,
    ...options,
  });
}

export function getReviews(param = {}, options = {}) {
  let url = `${LOCALAPI}/products/${param.key}`;

  return request({
    url,
    method: "get",
    // data: param,
    ...options,
  });
}

export function updateProduct(param, options = {}) {
  // console.log(param)
  let response = request({
    url: `${LOCALAPI}/products/${param.id}`,
    method: "put",
    data: param,
    ...options,
  });
  return response;
}

export function getShopifyCollections(params, options = {}) {
  let url;
  url = `${LOCALAPI}/user/shopify/collections`;

  return request({
    url,
    method: "get",
    data: params,
    ...options,
  });
}

export function getBigcommerceCollections(params, options = {}) {
  let url;
  url = `${LOCALAPI}/user/bigcommerce/collections`;

  return request({
    url,
    method: "get",
    data: params,
    ...options,
  });
}

export function getSquarespacePages(params, options = {}) {
  let url;
  url = `${LOCALAPI}/platform/squarespace/collections`;

  return request({
    url,
    method: "get",
    data: params,
    ...options,
  });
}

export function getWoocommerceCategories(params, options = {}) {
  let url;
  url = `${LOCALAPI}/platform/woocommerce/categories`;

  return request({
    url,
    method: "get",
    data: params,
    ...options,
  });
}

export function publishProduct(param, options = {}) {
  return request({
    url: `${LOCALAPI}/products/${param.id}/publishment`,
    method: "post",
    data: param,
    ...options,
  });
}
