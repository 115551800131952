export default {
  // example
  TICK: "TICK",
  INCREMENT: "INCREMENT",
  DECREMENT: "DECREMENT",
  RESET: "RESET",

  /*
   * APP 初始化ACTION
   */
  APP_INIT: "APP_INIT",

  /*
   * 常规设置
   */
  COMMON_SET_DEVICE: "COMMON_SET_DEVICE", // 浏览器设备

  /*
		视图控制
  **/
  UI_MOBILE_MENU: "UI_MOBILE_MENU", // 手机侧栏菜单开关
  UI_MOBILE_MENU_SECONDARY: "UI_MOBILE_MENU_SECONDARY", // 手机侧栏二级菜单开关
  UI_MOBILE_SEARCH_INPUT: "UI_MOBILE_SEARCH_INPUT", // 手机搜索输入展开开关
  UI_OVERLAY_SWITCH: "UI_OVERLAY_SWITCH", // 全局遮罩开关
  UI_MOBILE_DESIGN: "UI_MOBILE_DESIGN", // 手机定制页面开关

  ENQUEUE_SNACKBAR: "snackbar/enqueue",
  CLOSE_SNACKBAR: "snackbar/close",
  REMOVE_SNACKBAR: "snackbar/remove",

  /*
		社交网络分享
	**/
  SHARE_TO_SOCIETY_TRIGGER: "SHARE_TO_SOCIETY_TRIGGER", // 社交网络 分享

  /*
		产品
  **/
  PRODUCT_SET_PRODUCT_DETAIL_DATA: "PRODUCT_SET_PRODUCT_DETAIL_DATA", // 设置 产品 数据
  PRODUCT_SET_PRODUCT_SKU: "PRODUCT_SET_PRODUCT_SKU", // 设置 产品 SKU 数据
  PRODUCT_SET_PRODUCT_SELECTABLE_COLOR: "PRODUCT_SET_PRODUCT_SELECTABLE_COLOR", // 设置产品 可选颜色
  PRODUCT_SET_PRODUCT_IMAGE: "PRODUCT_SET_PRODUCT_IMAGE", // 设置 产品 图片
  PRODUCT_SET_PRODUCT_USED_FONT: "PRODUCT_SET_PRODUCT_USED_FONT", // 设置 产品 已使用字体
  PRODUCT_SET_PRODUCT_OPTION: "PRODUCT_SET_PRODUCT_OPTION", // 设置 产品选项
  PRODUCT_SET_PRODUCT_REVIEW_SUMMARY: "PRODUCT_SET_PRODUCT_REVIEW_SUMMARY", // 设置 产品 评论统计
  PRODUCT_SET_PRODUCT_REVIEW: "PRODUCT_SET_PRODUCT_REVIEW", // 设置 产品 评论 列表
  PRODUCT_SET_PRODUCT_TEMPLATE_DATA: "PRODUCT_SET_PRODUCT_TEMPLATE_DATA", // 设置产品 模板数据
  PRODUCT_SET_PRODUCT_LIST_DATA: "PRODUCT_SET_PRODUCT_LIST_DATA", // 设置 产品 列表数据
  PRODUCT_EDIT_PRODUCT_IMAGE: "PRODUCT_EDIT_PRODUCT_IMAGE",
  PRODUCT_DETAIL_SELECT_COLOR: "PRODUCT_DETAIL_SELECT_COLOR", // 选择 产品 颜色
  PRODUCT_SELECT_VARIANT: "PRODUCT_SELECT_VARIANT", // 选择 产品 选项

  PRODUCT_SHOW_VARIANT_SELECT_DIALOG: "PRODUCT_SHOW_VARIANT_SELECT_DIALOG", // 控制 vairant 选择器 弹框
  PRODUCT_ADD_TO_CART_STATUS: "PRODUCT_ADD_TO_CART_STATUS", // 控制 加入购物车 按钮状态

  /*
		购物车
  **/
  SHOPPING_CART_TRIGGER: "SHOPPING_CART_TRIGGER",
  SHOPPING_CART_EDIT_ITEM: "SHOPPING_CART_EDIT_ITEM",
  SHOPPING_CART_CALCULATE_TOTLE_PRICE: "SHOPPING_CART_CALCULATE_TOTLE_PRICE",

  /*
		结算
  **/
  CHECKOUT_TRIGGER: "CHECKOUT_TRIGGER",
  CHECKOUT_SWITCH_STEP: "CHECKOUT_SWITCH_STEP",
  CHECKOUT_USE_SAME_ADDRESS: "CHECKOUT_USE_SAME_ADDRESS",
  CHECKOUT_CHANGE_SHIPPING_ADDRESS: "CHECKOUT_CHANGE_SHIPPING_ADDRESS",
  CHECKOUT_CHANGE_BILLING_ADDRESS: "CHECKOUT_CHANGE_BILLING_ADDRESS",
  CHECKOUT_CHANGE_CREDIT_CARD: "CHECKOUT_CHANGE_CREDIT_CARD",

  /*
    Products页面 (/custom页面)
  */
  EFFECT_TEMPLATES_LOAD: "EFFECT_TEMPLATES_LOAD",
  TEMPLATES_LIST_SET: "TEMPLATES_LIST_SET",

  /*
   * Dashboard 页面
   */
  //home
  EFFECT_HOME_LOAD: "EFFECT_HOME_LOAD",
  DASHBOARD_HOME_DATA_SET: "DASHBOARD_HOME_DATA_SET",

  //product
  EFFECT_DASHBOARD_PRODUCTS_LOAD: "EFFECT_DASHBOARD_PRODUCTS_LOAD",
  DASHBOARD_PRODUCTS_SET: "DASHBOARD_PRODUCTS_SET",
  //library
  DASHBOARD_LIBRARY_SET: "DASHBOARD_LIBRARY_SET",
  EFFECT_DASHBOARD_LIBRARY_LOAD: "EFFECT_DASHBOARD_LIBRARY_LOAD",
  //Orders
  DASHBOARD_ORDERS_SET: "DASHBOARD_ORDERS_SET",
  EFFECT_DASHBOARD_ORDERS_LOAD: "EFFECT_DASHBOARD_ORDERS_LOAD",

  //Template
  TEMPLATE_INIT_SET: "TEMPLATE_INIT_SET",
  EFFECT_TEMPLATE_LOAD: "EFFECT_TEMPLATE_LOAD",
  EFFECT_TEMPLATE_CUSTOMIZER_LOAD: "EFFECT_TEMPLATE_CUSTOMIZER_LOAD",

  //OrdersDetail
  EFFECT_DASHBOARD_ORDERS_DETAIL_LOAD: "EFFECT_DASHBOARD_ORDERS_DETAIL_LOAD",
  DASHBOARD_ORDERS_DETAIL_SET: "DASHBOARD_ORDERS_DETAIL_SET",
  DASHBOARD_ORDERS_DETAIL_UPDATE: "DASHBOARD_ORDERS_DETAIL_UPDATE",

  //ProductsDetail
  EFFECT_DASHBOARD_PRODUCTS_DETAIL_LOAD:
    "EFFECT_DASHBOARD_PRODUCTS_DETAIL_LOAD",
  DASHBOARD_PRODUCTS_DETAIL_SET: "DASHBOARD_PRODUCTS_DETAIL_SET",
  DASHBOARD_PRODUCTS_DETAIL_UPDATE: "DASHBOARD_PRODUCTS_DETAIL_UPDATE",
  EFFECT_DASHBOARD_PRODUCTS_DETAIL_UPDATE:
    "EFFECT_DASHBOARD_PRODUCTS_DETAIL_UPDATE",

  //integrations
  EFFECT_DASHBOARD_INTEGRATIONS_LOAD: "EFFECT_DASHBOARD_INTEGRATIONS_LOAD",
  DASHBOARD_INTEGRATIONS_SET: "DASHBOARD_INTEGRATIONS_SET",

  //Shipping
  EFFECT_DASHBOARD_SHIPPING_PROFILES_LOAD:
    "EFFECT_DASHBOARD_SHIPPING_PROFILES_LOAD",
  DASHBOARD_SHIPPING_SET: "DASHBOARD_SHIPPING_SET",
  EFFECT_DASHBOARD_SHIPPING_PROFILES_DETAIL_LOAD:
    "EFFECT_DASHBOARD_SHIPPING_PROFILES_DETAIL_LOAD",
  DASHBOARD_SHIPPING_PROFILE_DETAIL_SET:
    "DASHBOARD_SHIPPING_PROFILE_DETAIL_SET",

  //Payment
  DASHBOARD_CARD_SET: "DASHBOARD_CARD_SET",
  EFFECT_DASHBOARD_CARDS_LOAD: "EFFECT_DASHBOARD_CARDS_LOAD",

  //invoice
  DASHBOARD_INVOICES_SET: "DASHBOARD_INVOICES_SET",
  EFFECT_DASHBOARD_INVOICES_LOAD: "EFFECT_DASHBOARD_INVOICES_LOAD",

  //提交产品后的产品发布页面
  EFFECT_PRODUCT_DETAILS_LOAD: "EFFECT_PRODUCT_DETAILS_LOAD",
  PRODUCT_DETAILS_SET: "PRODUCT_DETAILS_SET",
  PRODUCT_DETAILS_UPDATE: "PRODUCT_DETAILS_UPDATE",
  EFFECT_PRODUCT_DETAILS_UPDATE: "EFFECT_PRODUCT_DETAILS_UPDATE",

  //APP全局数据
  EFFECT_APP_COUNTRIES_LOAD: "EFFECT_APP_COUNTRIES_LOAD",
  APP_COUNTRIES_SET: "APP_COUNTRIES_SET",

  EFFECT_SHOPIFY_REVIEW: "EFFECT_SHOPIFY_REVIEW",
  APP_SHOPIFY_REVIEW: "APP_SHOPIFY_REVIEW",

  EFFECT_DASHBOARD_CARDS_LOAD: "EFFECT_DASHBOARD_CARDS_LOAD",

  EFFECT_SHIPPING_PRICES_LOAD: "EFFECT_SHIPPING_PRICES_LOAD",
  APP_SHIPPING_PRICES_SET: "APP_SHIPPING_PRICES_SET",

  RESET_PASSWORD_INIT: "RESET_PASSWORD_INIT",

  APP_USER_SET: "APP_USER_SET",

  // admin
  EFFECT_ADMIN_TEMPLATE_LOAD: "EFFECT_ADMIN_TEMPLATE_LOAD",

  // shoppingCart
  SHOPPING_CART_EDIT: "SHOPPING_CART_EDIT",
  SHOPPING_CART_EDIT_ITEM: "SHOPPING_CART_EDIT_ITEM",

  EFFECT_CART_LOAD: "EFFECT_CART_LOAD",
  EFFECT_CART_UPDATE: "EFFECT_CART_UPDATE",

  EFFECT_DASHBOARD_PACKAGE_DETAIL_LOAD: "effect/dashboard/package/load",

  EFFECT_DASHBOARD_WAREHOUSE_INVENTORY_LIST_LOAD:
    "effect/dashboard/inventory_list/load",
  EFFECT_DASHBOARD_WAREHOUSE_ORDER_LIST_LOAD:
    "effect/dashboard/order_list/load",
  DASHBOARD_INIT: "dashboard/init", // 初始化dashaboard数据
};
