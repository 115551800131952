import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { fetchTemplate } from '@services/admin'
import ActionTypes from '../ActionTypes';

function* fetchTemplateEffect({type, payload, options}) {
  // try {
    yield put({type: ActionTypes.TEMPLATE_INIT_SET, data: {loading: true}})
    let data = yield call(fetchTemplate, payload, options)
    data = data.data.template
    yield put({type: ActionTypes.TEMPLATE_INIT_SET, data: {
      id: data.id,
      template_no: data.template_no,
      category: data.category,
      title: data.title,
      suggested_title: data.suggested_title,
      description: data.description,
      image: data.image,
      images: data.images,
      options: data.options,
      variants: data.variants,
      areas: data.areas,
      scenes: data.scenes,
      models: data.models,
      cost: data.cost,
      suggested_retail_price: data.suggested_retail_price,
      recommend: data.recommend,
      // design: data.design,
      loading: false
    }})

    // yield initCustomizer(data)

    if(payload.resolve) {
      payload.resolve(data)
    }

  // } catch (e) {
  //   yield put({type: ActionTypes.TEMPLATE_INIT_SET, data: {loading: false}})
  //   if(payload.reject) {
  //     payload.reject()
  //   }
  // }
}


function* watch() {
  yield takeLatest(ActionTypes.EFFECT_ADMIN_TEMPLATE_LOAD, fetchTemplateEffect)
}

export default watch
