const palette = {
  mode: "light",
  primary: {
    // lighter: '#C8FACD',
    // light: '#5BE584',
    // main: '#00AB55',
    // dark: '#007B55',
    // darker: '#005249',

    lighter: "#C8FACD",
    light: "#60AB8A",
    main: "#3D9970",
    dark: "#327E5C",
    darker: "#005249",
  },
  // secondary: {
  //   // light: '#EE7769', //红色
  //   // main: '#ED6A5A',
  //   // dark: '#D86152',
  //
  //
  //   lighter: '#FFE7D9',
  //   light: '#FFA48D',
  //   // main: '#3D9970',
  //   main: '#FF4842',
  //   dark: '#B72136',
  //   darker: '#7A0C2E',
  // },
  thirdary: {
    light: "#7c7c7c",
    main: "#5d5d5d",
    dark: "#414141",
  },
  secondary: {
    lighter: "#FFE7D9",
    light: "#FFA48D",
    main: "#FF4842",
    dark: "#B72136",
    darker: "#7A0C2E",
  },
  warning: {
    lighter: "#FFF7CD",
    light: "#FFE16A",
    main: "#FFC107",
    dark: "#B78103",
    darker: "#7A4F01",
  },
  info: {
    lighter: "#D0F2FF",
    light: "#74CAFF",
    main: "#1890FF",
    dark: "#0C53B7",
    darker: "#04297A",
  },
  default: {
    main: "#000000de",
    dark: "#e0e0e0",
    contrastText: "#000000de",
  },
  grey: {
    main: "#e0e0e0",
    dark: "#d5d5d5",
    contrastText: "#000000de",
  },
};

export { palette };
