import inventories from "./inventories";
import warehouseOrderList from "./warehouseOrderList";

export default {
  products: {
    list: [],
    loading: false,
    page: 1,
    size: 15,
    count: 0,
  },
  library: {
    list: [],
    loading: false,
    page: 1,
    size: 16,
    count: 0,
    keywords: "",
  },
  orders: {
    list: [],
    loading: false,
    page: 1,
    size: 20,
    count: 0,
    filter: {
      status: "ALL",
      date: "anytime",
    },
  },
  integrations: {
    loading: false,
    platforms: [],
  },
  home: {
    week_order: [],
    recent_order: {},
    popular_template: [],
  },
  ordersDetail: {
    asPath: false,
    query: false,
    loading: false,
    order: {
      line_items: [],
      shipping_address: {},
    },
  },
  productsDetail: {
    asPath: false,
    query: false,
    loading: false,
    needSync: false,
  },
  shipping: {
    loading: false,
    shipping_profiles: [],
  },
  shippingProfile: {
    loading: false,
    shipping_profile: {},
    shipping_profiles: [],
  },
  payment: {
    loading: false,
    creditCards: [],
  },
  invoices: {
    loading: false,
    page: 1,
    size: 25,
    count: 0,
    invoices: [],
    billingAddress: null,
  },
  packageList: {
    loading: false,
    packages: [],
  },
  packageDetail: {
    loading: false,
    brand_service: null,
    products: [],
  },
  productInventories: inventories,
  packageInventories: inventories,
  warehouseOrderList,
};
